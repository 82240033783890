<section
  class="benefits-card"
  *ngIf="!viewState.isLoading"
  [ngClass]="{ 'benefits-card--selected': viewState.isSelectedTier }"
>
  <p *ngIf="viewState.isSelectedTier" class="benefits-card__selected-tag caption1--medium">
    {{ 'LOYALTY_PROGRAM.TIER_BENEFITS.SELECTED' | translate }}
  </p>
  <section class="benefits-card__header">
    <img [src]="viewState.icon" alt="icon" class="benefits-card__header__img" />
    <p class="benefits-card__header__text body1--bold">
      {{ viewState.name }}
    </p>
  </section>
  <section>
    <p *ngIf="viewState.shouldDisplayDisclaimer" class="benefits-card__note body2-regular">
      {{ 'LOYALTY_PROGRAM.TIER_BENEFITS.PREVIOUS_BENEFITS' | translate }}
    </p>
  </section>
  <section *ngFor="let benefit of viewState.benefits" class="benefits-card__description">
    <img [src]="benefit.icon" alt="icon" class="benefits-card__description__img" />
    <p class="benefits-card__description__text body2-regular">
      {{ benefit.description }}
    </p>
  </section>
</section>
