import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TierModel } from 'src/app/core/domain/loyalty-program/loyalty-program.model';
import { BaseComponent } from '../../base/base.component';
import { TiersBenefitCardPresenter } from './presentation/tiers-benefits-card.presenter';
import { TiersBenefitCardSideEffect } from './presentation/tiers-benefits-card.side-effects';
import { TiersBenefitCardViewEvent } from './presentation/tiers-benefits-card.view-events';
import { TiersBenefitCardViewState } from './presentation/tiers-benefits-card.view-state';

@Component({
  selector: 'app-tier-benefits-card',
  templateUrl: './tier-benefits-card.component.html',
  styleUrls: ['./tier-benefits-card.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, TranslateModule, NgClass],
  providers: [TiersBenefitCardPresenter],
})
export class TierBenefitsCardComponent extends BaseComponent<
  TiersBenefitCardPresenter,
  TiersBenefitCardViewState,
  TiersBenefitCardViewEvent,
  TiersBenefitCardSideEffect
> {
  @Input() tier: TierModel;

  @Input() currentTierIndex: number;

  public presenter: TiersBenefitCardPresenter = inject(TiersBenefitCardPresenter);

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
      tier: this.tier,
      currentTierIndex: this.currentTierIndex,
    });
  }
}
