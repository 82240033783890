import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideRouter } from '@angular/router';
import { LoyaltyProgramComponent } from './loyalty-program.component';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideRouter([
      {
        path: '',
        component: LoyaltyProgramComponent,
      },
    ]),
  ],
  declarations: [],
})
export class LoyaltyModule {}
