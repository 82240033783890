// tiers-benefits-card Presenter

import { Injectable } from '@angular/core';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { TiersBenefitCardSideEffect } from './tiers-benefits-card.side-effects';
import { TiersBenefitCardViewEvent } from './tiers-benefits-card.view-events';
import { TiersBenefitCardViewState } from './tiers-benefits-card.view-state';

@Injectable({ providedIn: 'root' })
export class TiersBenefitCardPresenter extends BasePresenter<
  TiersBenefitCardViewState,
  TiersBenefitCardViewEvent,
  TiersBenefitCardSideEffect
> {
  constructor(private _siteTranslateService: SiteTranslateService) {
    super();
  }

  protected defaultViewState(): TiersBenefitCardViewState {
    return {
      isLoading: true,
      name: '',
      icon: '',
      shouldDisplayDisclaimer: false,
      benefits: [],
      isSelectedTier: false,
    };
  }

  protected onViewEvent(event: TiersBenefitCardViewEvent): void {
    switch (event.type) {
      case 'Init': {
        this.updateViewState({
          ...this.viewState,
          isLoading: false,
          name:
            this._siteTranslateService.getCurrentLanguage() === 'en'
              ? event.tier.displayName.en!
              : event.tier.displayName.ar,
          icon: event.tier.icon,
          shouldDisplayDisclaimer: !(event.tier.index === 0),
          benefits: event.tier.benefits.map((benefit) => {
            return {
              icon: benefit.icon,
              description:
                this._siteTranslateService.getCurrentLanguage() === 'en'
                  ? benefit.description.en!
                  : benefit.description.ar,
            };
          }),
          isSelectedTier: Number(event.currentTierIndex) === Number(event.tier.index),
        });
        this.add(
          this._siteTranslateService.onLanguageChange().subscribe((lang) => {
            this.updateViewState({
              ...this.viewState,
              name: lang === 'en' ? event.tier.displayName.en! : event.tier.displayName.ar,
              benefits: event.tier.benefits.map((benefit) => {
                return {
                  ...benefit,
                  description: lang === 'en' ? benefit.description.en! : benefit.description.ar,
                };
              }),
            });
          }),
        );
        break;
      }
    }
  }
}
