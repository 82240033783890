<loader size="lg" [loading]="viewState.isLoading"></loader>
<div class="loyalty-program" *ngIf="!viewState.isLoading">
  <div class="loyalty-program__top">
    <div class="loyalty-program__top__right">
      <app-tiering-progress-bar></app-tiering-progress-bar>
    </div>
    <div class="order-progress loyalty-program__top__left">
      <p class="body1--bold blue">{{ 'LOYALTY_PROGRAM.ORDER_PROGRESS' | translate }}</p>
      <div class="same-row">
        <div class="order-progress__card">
          <p class="white body2--bold">{{ 'LOYALTY_PROGRAM.CONFIRMED_ORDERS' | translate }}</p>
          <p class="gold heading2--bold">{{ viewState.achievedOrders }}</p>
        </div>
        <div class="order-progress__card">
          <p class="white body2--bold">
            {{ 'LOYALTY_PROGRAM.IN_PROGRESS_ORDERS' | translate }}
          </p>
          <p class="gold heading2--bold">{{ viewState.inProgressOrders }}</p>
        </div>
      </div>
      <p class="caption1--bold white">{{ 'LOYALTY_PROGRAM.RENEW' | translate }}</p>
      <p class="caption1--bold white">{{ 'LOYALTY_PROGRAM.START' | translate }}</p>
    </div>
  </div>
  <div class="loyalty-program__benefits">
    <p class="heading2--bold white">{{ 'LOYALTY_PROGRAM.BENEFITS' | translate }}</p>
    <div class="loyalty-program__benefits__disclaimer">
      <img src="assets/img/speaker.svg" alt="speaker" />
      <p class="body2-medium gold">
        {{ 'LOYALTY_PROGRAM.TIER_BENEFITS.DISCLAIMER' | translate }}
      </p>
    </div>
    <div class="loyalty-program__benefits__container">
      <app-tier-benefits-card
        *ngFor="let item of viewState.tiersData"
        [tier]="item"
        [currentTierIndex]="viewState.actualLoyaltyTierIndex"
      ></app-tier-benefits-card>
    </div>
  </div>
</div>
<div>
  <app-faq [questions]="viewState.faqQuestions"></app-faq>
</div>
