// loyalty-program Presenter

import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { GetLoyaltyFAQsDataUseCase } from 'src/app/core/usecases/loyalty-program/get-faqs-data.usecase';
import { GetLoyaltyProgressUseCase } from 'src/app/core/usecases/loyalty-program/get-progress.usecase';
import { GetLoyaltyTiersDataUseCase } from 'src/app/core/usecases/loyalty-program/get-tiers-data.usecase';
import { BasePresenter } from '../../base/base.presenter';
import { LoyaltyProgramSideEffects } from './loyalty-program.side-effects';
import { LoyaltyProgramViewEvent } from './loyalty-program.view-events';
import { LoyaltyProgramViewState } from './loyalty-program.view-state';

@Injectable({ providedIn: 'root' })
export class LoyaltyProgramPresenter extends BasePresenter<
  LoyaltyProgramViewState,
  LoyaltyProgramViewEvent,
  LoyaltyProgramSideEffects
> {
  constructor(
    private _getLoyaltyTiersDataUseCase: GetLoyaltyTiersDataUseCase,
    private _getLoyaltyFAQsDataUseCase: GetLoyaltyFAQsDataUseCase,
    private _getLoyaltyProgressUseCase: GetLoyaltyProgressUseCase,
  ) {
    super();
  }

  protected defaultViewState(): LoyaltyProgramViewState {
    return {
      achievedOrders: '0',
      inProgressOrders: '0',
      actualLoyaltyTierIndex: '0',
      isLoading: true,
      tiersData: [],
      faqQuestions: [],
    };
  }

  protected onViewEvent(event: LoyaltyProgramViewEvent): void {
    switch (event.type) {
      case 'Init': {
        const loyaltyTiersData$ = this._getLoyaltyTiersDataUseCase.execute();
        const loyaltyFAQsData$ = this._getLoyaltyFAQsDataUseCase.execute();
        const loyaltyProgress$ = this._getLoyaltyProgressUseCase.execute();

        combineLatest([loyaltyTiersData$, loyaltyFAQsData$, loyaltyProgress$]).subscribe({
          next: ([loyaltyTiersData, loyaltyFAQsData, loyaltyProgress]) => {
            this.updateViewState({
              ...this.viewState,
              isLoading: false,
              achievedOrders: loyaltyProgress.achievedOrders.toString(),
              inProgressOrders: loyaltyProgress.inProgressOrders.toString(),
              actualLoyaltyTierIndex: loyaltyProgress.actualLoyaltyTierIndex.toString(),
              tiersData: loyaltyTiersData,
              faqQuestions: loyaltyFAQsData,
            });
          },
          error: () => {
            this.updateViewState({
              ...this.viewState,
              isLoading: false,
            });
          },
        });
      }
    }
  }
}
