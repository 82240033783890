import { NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '../base/base.component';
import { TieringProgressBarComponent } from '../products/products-v2/tiering-progress-bar/tiering-progress-bar.component';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { FaqComponent } from '../shared/containers/logged-out-layout/components/landing/faq/faq.component';
import { LoyaltyProgramPresenter } from './presentation/loyalty-program.presenter';
import { LoyaltyProgramSideEffects } from './presentation/loyalty-program.side-effects';
import { LoyaltyProgramViewEvent } from './presentation/loyalty-program.view-events';
import { LoyaltyProgramViewState } from './presentation/loyalty-program.view-state';
import { TierBenefitsCardComponent } from './tier-benefits-card/tier-benefits-card.component';

@Component({
  selector: 'app-loyalty-program',
  standalone: true,
  imports: [
    TranslateModule,
    TieringProgressBarComponent,
    TierBenefitsCardComponent,
    NgFor,
    FaqComponent,
    NgIf,
    LoaderComponent,
  ],
  templateUrl: './loyalty-program.component.html',
  styleUrls: ['./loyalty-program.component.scss'],
  providers: [LoyaltyProgramPresenter],
})
export class LoyaltyProgramComponent extends BaseComponent<
  LoyaltyProgramPresenter,
  LoyaltyProgramViewState,
  LoyaltyProgramViewEvent,
  LoyaltyProgramSideEffects
> {
  public presenter: LoyaltyProgramPresenter = inject(LoyaltyProgramPresenter);

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
    });
  }
}
